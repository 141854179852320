<template>
  <section class="multistep">
    <div class="container">
      <div class="steps-count">
        <div class="steps">
          <a class="active uk-disabled">1</a>
          <a v-if="stepHasValues(2)" @click="moveToStep('secondStep')">2</a>
          <a v-if="!stepHasValues(2)" class="uk-disabled">2</a>

          <a v-if="stepHasValues(3)" @click="moveToStep('thirdStep')">3</a>
          <a v-if="!stepHasValues(3)" class="uk-disabled">3</a>

          <a v-if="stepHasValues(4)" @click="moveToStep('fourthStep')">4</a>
          <a v-if="!stepHasValues(4)" class="uk-disabled">4</a>
        </div>
      </div>
      <div class="content">
        <h1>Vložení nemovitosti k prodeji</h1>
        <p>Je to snadné, jednoduché a rychlé! Za 10 minut máte hotovo. Za vložení inzerátu nic neplatíte.</p>
        <form class="form-label form-js-label">
          <div class="row">
            <h3>Typ nemovitosti</h3>
            <h4 v-if="validated && !step.zadanytyp" class="error">Vyberte typ vaší nemovitosti:</h4>
            <p v-if="validated && !step.zadanytyp" class="error">(Klikněte na typ vaší nemovitosti a následně na druh! V tuto chvíli není správně vybráno)</p>
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" v-model="step.zadanytyp" value="6" id="estate" name="realestate" :class="{ 'active': step.zadanytyp == 6}" @click="setTyp(6)">
                  <label for="estate"><img src="../../assets/images/ico-radio1.svg" alt="icon" data-uk-svg><span>Domy a chaty</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" v-model="step.zadanytyp" value="4" id="estate2" name="realestate" :class="{ 'active': step.zadanytyp == 4}" @click="setTyp(4)">
                  <label for="estate2"><img src="../../assets/images/ico-radio2.svg" alt="icon" data-uk-svg><span>Byty</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" v-model="step.zadanytyp" value="3" id="estate3" name="realestate" :class="{ 'active': step.zadanytyp == 3}" @click="setTyp(3)">
                  <label for="estate3"><img src="../../assets/images/ico-radio4.svg" alt="icon" data-uk-svg><span>Pozemky</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" v-model="step.zadanytyp" value="2" id="estate4" name="realestate" v-bind:class="{ 'active': step.zadanytyp == 2}" @click="setTyp(2)">
                  <label for="estate4"><img src="../../assets/images/ico-radio3.svg" alt="icon" data-uk-svg><span>Komerční</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" v-model="step.zadanytyp" value="11" id="estate5" name="realestate" v-bind:class="{ 'active': step.zadanytyp == 11}" @click="setTyp(11)">
                  <label for="estate5"><img src="../../assets/images/ico-radio5.svg" alt="icon" data-uk-svg><span>Ostaní</span></label>
                </div>
              </div>
            </div>
          </div>
          <div class="row-list" v-if="step.zadanytyp == 6">
            <div class="row" >
            <h3>Druh nemovitosti</h3>
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="15" v-model="step.druh" id="species" name="speciesestate">
                  <label for="species"><span>Rodinné</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="16" v-model="step.druh" id="species2" name="speciesestate">
                  <label for="species2"><span>Vily</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="560" v-model="step.druh" id="species3" name="speciesestate">
                  <label for="species3"><span>Chata</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="561" v-model="step.druh" id="species4" name="speciesestate">
                  <label for="species4"><span>Chalupa</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="188" v-model="step.druh" id="species5" name="speciesestate">
                  <label for="species5"><span>jiný</span></label>
                </div>
              </div>
            </div>
            </div>
            <div class="row" v-if="step.zadanytyp == 6 && step.druh != null">
              <h3>Velikost</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="1" v-model="step.pocet_pokoju" id="size" name="sizehome">
                    <label for="size"><span>1 pokoj</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="2" v-model="step.pocet_pokoju" id="size2" name="sizehome">
                    <label for="size2"><span>2 pokoje</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="3" v-model="step.pocet_pokoju" id="size3" name="sizehome">
                    <label for="size3"><span>3 pokoje</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="4" v-model="step.pocet_pokoju" id="size4" name="sizehome">
                    <label for="size4"><span>4 pokoje</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="5" v-model="step.pocet_pokoju" id="size5" name="sizehome">
                    <label for="size5"><span>5 a více pokojů</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="6" v-model="step.pocet_pokoju" id="size6" name="sizehome">
                    <label for="size6"><span>atypický</span></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-list" v-if="step.zadanytyp == 4">
            <div class="row" >
            <h3>Velikost</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="112" v-model="step.druh" id="size" name="sizeestate">
                    <label for="size"><span>1+kk</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="113" v-model="step.druh" id="size2" name="sizeestate">
                    <label for="size2"><span>1+1</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="122" v-model="step.druh" id="size3" name="sizeestate">
                    <label for="size3"><span>2+kk</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="123" v-model="step.druh" id="size4" name="sizeestate">
                    <label for="size4"><span>2+1</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="125" v-model="step.druh" id="size5" name="sizeestate">
                    <label for="size5"><span>3+kk</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="126" v-model="step.druh" id="size6" name="sizeestate">
                    <label for="size6"><span>3+1</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="132" v-model="step.druh" id="size7" name="sizeestate">
                    <label for="size7"><span>4+kk</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="133" v-model="step.druh" id="size8" name="sizeestate">
                    <label for="size8"><span>4+1</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="135" v-model="step.druh" id="size9" name="sizeestate">
                    <label for="size9"><span>5+kk</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="136" v-model="step.druh" id="size10" name="sizeestate">
                    <label for="size10"><span>5+1</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="137" v-model="step.druh" id="size11" name="sizeestate">
                    <label for="size11"><span>6 a více</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="1370" v-model="step.druh" id="size12" name="sizeestate">
                    <label for="size12"><span>atypický</span></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-list" v-if="step.zadanytyp == 3">
            <div class="row">
              <h3>Typ pozemku</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="33" v-model="step.druh" id="ground1" name="ground">
                    <label for="ground1"><span>Pro bydlení</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="34" v-model="step.druh" id="ground2" name="ground">
                    <label for="ground2"><span>Pro komerční výstavbu</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="31" v-model="step.druh" id="ground3" name="ground">
                    <label for="ground3"><span>Zemědělská půda</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="38" v-model="step.druh" id="ground4" name="ground">
                    <label for="ground4"><span>Les</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="32" v-model="step.druh" id="ground5" name="ground">
                    <label for="ground5"><span>Zahrada</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="35" v-model="step.druh" id="ground6" name="ground">
                    <label for="ground6"><span>Rybníky, vodní plocha</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="36" v-model="step.druh" id="ground7" name="ground">
                    <label for="ground7"><span>Jiný / ostatní</span></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-list" v-if="step.zadanytyp == 2">
            <div class="row">
              <h3>Druh</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="27" v-model="step.druh" id="com1" name="commercial">
                    <label for="com1"><span>Kanceláře</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="28" v-model="step.druh" id="com2" name="commercial">
                    <label for="com2"><span>Sklady</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="25" v-model="step.druh" id="com3" name="commercial">
                    <label for="com3"><span>Výroba</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="21" v-model="step.druh" id="com4" name="commercial">
                    <label for="com4"><span>Obchodní prostory</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="221" v-model="step.druh" id="com5" name="commercial">
                    <label for="com5"><span>Restaurace</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="222" v-model="step.druh" id="com6" name="commercial">
                    <label for="com6"><span>Ubytování</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="23" v-model="step.druh" id="com7" name="commercial">
                    <label for="com7"><span>Zemědělské projekty</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="224" v-model="step.druh" id="com8" name="commercial">
                    <label for="com8"><span>Jiné / ostatní</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="17" v-model="step.druh" id="com9" name="commercial">
                    <label for="com9"><span>Činžovní dům</span></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row-list" v-if="step.zadanytyp == 11">
            <div class="row">
              <h3>Typ nemovitosti</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="41" v-model="step.druh" id="ot1" name="other">
                    <label for="ot1"><span>Garáž</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="410" v-model="step.druh" id="ot2" name="other">
                    <label for="ot2"><span>Garážové stání</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="420" v-model="step.druh" id="ot3" name="other">
                    <label for="ot3"><span>Vinný sklep</span></label>
                  </div>
                </div>
                <div class="uk-width-1-2">
                  <div class="row-row">
                    <input type="radio" value="42" v-model="step.druh" id="ot4" name="other">
                    <label for="ot4"><span>Ostatní</span></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <h3>Vlastnictví</h3>
            <p v-if="validated && !step.vlastnictvi" class="error">Zadejte vlastnictví</p>
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="1" v-model="step.vlastnictvi" id="property" name="propertyestate">
                  <label for="property"><span>Osobní</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="2" v-model="step.vlastnictvi" id="property2" name="propertyestate">
                  <label for="property2"><span>Družstevní</span></label>
                </div>
              </div>
              <div class="uk-width-1-2">
                <div class="row-row">
                  <input type="radio" value="4" v-model="step.vlastnictvi" id="property3" name="propertyestate">
                  <label for="property3"><span>Jiné</span></label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <fieldset :class="{error: !step.nazev && validated, succes: step.nazev}">
              <input type="text" name="name" id="name" v-model="step.nazev">
              <label for="name" class="yes">*Název nemovitosti</label>
            </fieldset>
            <fieldset :class="{error: validated && step.popis_cz.length < 100, succes: step.popis_cz.length >= 100}">
              <textarea id="textname" v-model="step.popis_cz"></textarea>
              <label for="textname" class="yes">*Popis nemovitosti</label>
            </fieldset>
            <p>Napsali jste: {{step.popis_cz.length}} znaků ze 100 povinných.</p>
            <p v-if="validated && step.popis_cz.length < 100" class="error">
              Text je příliš krátký a to {{step.popis_cz.length}} znaků. Minimální počet je 100 znaků.
            </p>
          </div>
          <div class="row">
            <h3>Adresa prodávané nemovitosti</h3>
            <fieldset :class="{error: !address.place && validated, succes: address.place}">
              <input type="text" name="name" id="place" placeholder="např.: Radlická 608/2, Praha 5" class="input-maps" v-model="address.place">
              <p class="button-right">
                <a v-if="isGpsAvailable()" class="buttongrey green"  id="gpsAvailable">
                    Načíst dle GPS <img v-if="!gpsSpinner" src="../../assets/images/ico-gps.png"> <span v-if="gpsSpinner" uk-spinner="ratio 0.1"></span>
                </a>
                <a v-else href="#" class="buttongrey uk-disabled">GPS není dostupná <img src="../../assets/images/ico-gps.png"></a>
              </p>
              <p v-if="addressAlert.length > 0" class="error">{{addressAlert}}</p>
            </fieldset>
          </div>
          <div class="row">
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-1">
                <fieldset>
                  <input type="text" name="name" v-model="address.street" id="street" :disabled="!address.ob"  placeholder="Ulice">
                </fieldset>
              </div>
              <div class="uk-width-1-3">
                <fieldset>
                  <input type="text" name="name" v-model="address.cp" id="cp" :disabled="!address.ob" placeholder="Č. popisné">
                </fieldset>
              </div>
              <div class="uk-width-1-3">
                <fieldset>
                  <input type="text" name="name" v-model="address.co" id="co" :disabled="!address.ob" placeholder="Č. orientační">
                </fieldset>
              </div>
              <div class="uk-width-1-3">
                <fieldset>
                  <input type="text" name="name" v-model="address.ce" id="ce" :disabled="!address.ob" placeholder="Č. evidenční">
                </fieldset>
              </div>
              <div class="uk-width-1-2">
                <!-- <fieldset :class="{error: katastrErrorMessage != ''}"> -->
                <fieldset>
                  <input type="text" name="name" v-model="address.ob" id="ob" disabled placeholder="Obec">
                  <!-- <p v-if="katastrErrorMessage != ''" :class="{error: katastrErrorMessage != ''}">{{ katastrErrorMessage }}</p> -->
                </fieldset>
              </div>
              <div class="uk-width-1-2">
                <fieldset>
                  <input type="text" name="name" v-model="address.cbo" id="cbo" disabled placeholder="Část obce">
                </fieldset>
              </div>
              <div class="uk-width-1-3">
                <fieldset>
                  <input type="text" name="name" v-model="address.psc" id="psc" disabled placeholder="PSČ">
                  <input type="hidden" name="districtName" v-model="address.distName" id="disName" disabled>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row end">
            <router-link to="/" class="button white small">Zpět</router-link>
            <a @click="moveToStep('secondStep')" class="button small">Pokračovat dále</a>
          </div>
        </form>
      </div>
    </div>
  </section>
  <modal :show="gpsModal" @close="gpsModal = false">
    <template #header>
      <h3>Dle souřadnic GPS jsme se nacházíte</h3>
    </template>
    <template #body>
      <p><span class="bold">Město:</span> {{address.ob}}</p>
      <p><span class="bold">Městská část:</span> {{address.cbo}}</p>
      <p><span class="bold">Ulice:</span> {{address.street}}</p>
      <p><span class="bold">Číslo popisné:</span> {{address.cp}}</p>
      <p><span class="bold">Číslo orientační:</span> {{address.co}}</p>
    </template>
  </modal>
  <!-- <modal :show="errorModal" @close="errorModal = false">
    <template #header>
      <h3 class="error">Chyba!</h3>
    </template>
    <template #body>
      <p>{{ katastrErrorMessage }}</p>
    </template>
  </modal> -->
</template>

<script>
import okres from "../../../public/ciselniky/okres";
import katastr from "../../../public/ciselniky/katastr";
import obce from "../../../public/ciselniky/obce";
import initsugesster from "../../../libs/smap/init";
import goecoder from "../../../libs/smap/geocoder-reverse";
import eventBus from "../../../libs/event-bus";
import Delayer from "../../../libs/delayer";
import Modal from "@/components/general/modal";


export default {
  name: "FirstStep",
  components: {
    Modal
  },
  data(){
    return{
      katastrErrorMessage:'',
      errorModal:false,
      gpsSpinner: false,
      gpsModal: false,
      id: null,
      step: {
        zadanytyp: null,
        druh: null,
        pocet_pokoju: null,
        vlastnictvi: null,
        nazev: null,
        popis_cz: '',
      },
      address: {
        place: null,
        street: null,
        cp: null,
        co: null,
        ce: null,
        ob: null,
        cbo: null,
        psc: null,
        distName: null,
        okres: null,
        id_mesto: null,
        id_katastr: null,
        latitude: null,
        longitude: null,
      },
      popisAlert: '',
      addressAlert: '',
      layout: '',
      isActive: true,
      add: null,
      validated: false,
    }
  },
  created() { //naplnuje promene
    this.id = this.$route.params.id;
    let add = this.$store.getters.advertById(this.id);
    if( typeof add == 'undefined' ) {
      this.$router.push({ path: '/' });
      return;
    }
    if( add.activated ){
      this.$router.push({name: 'viewonly', params: {id: this.id}})
    }
    this.add = add;
    if( add.steps[1].nazev ){
      this.step = add.steps["1"];
    }
    if( add.address ){
      this.address = add.address;
    }
  },
  mounted() {
    if( document.readyState == 'complete' ){
      initsugesster.initSuggester();
      goecoder.initGeocoder()
    }else {
      window.addEventListener('load', initsugesster.initSuggester, false);
      window.addEventListener('load', goecoder.initGeocoder, false);
    }

    eventBus.$on('naseptavac', payload => {
      this.updateAddress(payload);
    } );

    eventBus.$on('gps', payload => {
      this.updateAddress(payload);
      this.gpsModal = true;
      this.gpsSpinner = false;
    } );

    eventBus.$on('gps-start', payload => {
      this.gpsSpinner = true;
    });
  },
  methods: {
    async save() {
      let valid = this.validate();
      if( !valid ) {
        Delayer.delay(600).then(()=> {
          document.querySelector(".error").scrollIntoView( { behavior: 'smooth'});
        });
        return false;
      }
      let okres = await this.getOkres()
      let katastr = await this.getKatastr();
      let obec = await this.getObec();
      if( okres ){
        this.address.okres = okres.id;
      }
      if( katastr ){
        this.address.id_katastr = katastr.id;
      }
      if( obec ){
        this.address.id_mesto = obec.id;
      }
      let advert = {
        id: this.id,
        step: this.step,
        address: this.address
      };
      this.$store.dispatch('updateStepOne', advert );
      this.$store.dispatch('updateAddress', advert );
      return true;
    },
    async moveToStep(stepName){
      if( await this.save() ){
        if(this.katastrErrorMessage != ''){
          return;
        }
        this.$router.push({ name: stepName, params: { id: this.id } }  );
      }
    },
    async getOkres(){
      return okres.okres.find( e => e.okres == this.address.distName );
    },
    async getKatastr(){
      return katastr.katastr.find(e => e.tmpnazev == this.address.ob );
    },
    async getObec(){
      // this.katastrErrorMessage = '';
      // this.katastrModal = false;
      try{
        let katastr = await this.getKatastr();
        if(!katastr) {
          throw new Error('Pro danou obec jsme nemohli najít žádný platný katastr. Zkuste ji upřesnit (např. Háje namísto obecného "Praha 5", apod.)');
          // this.katastrErrorMessage = 'Pro danou obec jsme nemohli najít žádný platný katastr. Zkuste ji upřesnit (např. Háje namísto obecného "Praha 5", apod.)'
          // this.errorModal = true;
          // return;
        }
        return obce.obce.find(e => e. nazev == this.address.ob && e.region_id == katastr.region_id && e.district_id == katastr.district_id );   
      } catch(err){
        console.log(err);
      }
    },
    updateAddress(address)
    {
      if( address.place ){
        this.address.place = address.place;
      }
      this.address.street = address.street;
      this.address.cp = address.cp;
      this.address.co = address.co;
      this.address.ce = address.ce;
      this.address.ob = address.ob;
      this.address.cbo = address.cbo;
      this.address.psc = address.psc;
      this.address.distName = address.distName;
      this.address.latitude = address.latitude;
      this.address.longitude = address.longitude;
    },
    isGpsAvailable()
    {
      return "geolocation" in navigator;
    },
    stepHasValues(step){
      if( !this.add ){
        return false;
      }

      return Object.keys(this.add.steps[step]).length > 0;
    },
    validate() {
      this.popisAlert = false;
      this.addressAlert = '';
      let valid = true;
      if( !this.step.nazev )
        valid = false;

      if( this.step.zadanytyp == 6 && !this.step.pocet_pokoju ){
          this.step.druh = null;
          this.step.zadanytyp = null;
          valid = false;
      }

      if( !this.step.druh){
        this.step.zadanytyp = null;
        valid = false;
      }

      if( !this.step.zadanytyp )
        valid = false;

      if( !this.step.vlastnictvi )
        valid = false;

      if( this.step.popis_cz.length < 100) {
        valid = false
      }

      if( !this.address.latitude || !this.address.longitude ){
        this.addressAlert = 'Adresa nemá platné GPS souřadnice, zadejte ji, prosím, znovu';
        return false;
      }

      if( !this.address.place ){
        this.addressAlert = 'Prosím vyplňte první pole adresy.'
      }

      this.validated = true;

      return valid;
    },
    setTyp(typId){
      if( this.step.zadanytyp != typId )
      {
        this.step.zadanytyp = typId;
        this.step.druh = null;
        this.step.pocet_pokoju = null;

        //resetujeme step 2
        this.$store.dispatch('updateStepTwo', {id: this.id, step: {}} );
        //this.$store.dispatch('updateStepThree', {id: this.id, step: {}} );
        this.$store.dispatch('updateStepFour', {id: this.id, step: {}} );
      }
    }
  },

}
</script>
<style lang="less">
  section.multistep{
    .container{
      position: relative;
      max-width: 42.8rem;
      height: 92vh;
      overflow: auto;
      margin: auto;
      background: #fff;
      box-sizing: border-box;
      padding-bottom: 4rem;
      .steps-count{
        background: #fff;
        padding: 1.8rem 0;
        .steps{
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
                  justify-content: space-between;
          -webkit-box-align: center;
          -ms-flex-align: center;
                  align-items: center;
          width: 100%;
          max-width: 27rem;
          margin: auto;
          a{
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 4.3rem;
            height: 4.3rem;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 2.1rem;
            line-height: 2.1rem;
            color: #03A3BD;
            background: #F2F2F2;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.16);
            &.active{
              background: #03A3BD;
              color: #fff;
              &:hover{
                background: #fff;
                color: #03A3BD;
              }
            }
            &:hover{
              text-decoration: none;
              background: #03A3BD;
              color: #fff;
            }
            &:before{
              content: "";
              position: absolute;
              top: 0;
              right: -2rem;
              bottom: 0;
              margin: auto;
              width: 0.9rem;
              height: 1.7rem;
              background: url('../../assets/images/ico-arrow-right.png');
              background-size: cover;
              background-repeat: no-repeat;
              z-index: 1;
            }
            &:last-of-type{
              &:before{
                content: none;
              }
            }
          }
        }
      }
      .content{
        padding: 2rem 2rem 6rem ;
        h1{
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3.5rem;
        text-align: center;
        color: #7C7C7B;
        margin-top: 0;
        margin-bottom: 1rem;
      }
      p{
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        text-align: center;
        max-width: 33rem;
        margin: auto;
        margin-top: 0;
        margin-bottom: 4rem;
        }
        form{
          .row{
            h3{
              font-family: 'Poppins';
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 2.1rem;
              color: #03A3BD;
              margin-top: 2rem;
              margin-bottom: 1rem;
            }
            .uk-grid{
              >div{
                .row-row{
                  position: relative;
                  height: 100%;
                  input{
                    position: absolute;
                    opacity: 0;
                    &:checked + label{
                      background:#03A3BD;
                      color: #fff;
                      border-color: #03A3BD;
                      span{
                        color: #fff;
                      }
                    }
                  }
                  label{
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                            align-items: center;
                    border: 1px solid #D6D6D6;
                    background: #F2F2F2;
                    color: #2FAC66;
                    box-sizing: border-box;
                    padding: 1rem 1.5rem;
                    -webkit-border-radius: 0.5rem;
                    -moz-border-radius: 0.5rem;
                    border-radius: 0.5rem;
                    height: 100%;
                    svg{
                      display: inline-block;
                      max-height: 3rem;
                      max-width: 4rem;
                      margin-right: 0.5rem;
                    }
                    span{
                      font-family: 'Poppins';
                      font-weight: 700;
                      font-size: 1.4rem;
                      line-height: 2.1rem;
                      color: #7C7C7B;
                    }
                    &.yes{
                      font-size: 12px;
                      -webkit-transform: translate3d(0, -2.5rem, 0);
                      transform: translate3d(0, -2.5rem, 0);
                      border: 1px solid #b8b8b8;
                      padding: 3px;
                      background:#fff;
                      border-radius: 3px;
                    }
                  }
                }
              }
            }
            fieldset{
              margin-top: 3rem;
              input{
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                        align-items: center;
                -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                padding: 0 2rem;
                width: 100%;
                height: 4rem;
                border: 1px solid #D6D6D6;
                -webkit-border-radius: 0.5rem;
                -moz-border-radius: 0.5rem;
                border-radius: 0.5rem;
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 2.1rem;
                color:#7C7C7B;
                &::-webkit-input-placeholder {
                  color: #7C7C7B;
                  font-weight: 400;
                  font-size: 14px;
                }
                &::-moz-placeholder {
                  color: #7C7C7B;
                  font-weight: 400;
                  font-size: 14px;
                }
                &:-ms-input-placeholder { 
                  color: #7C7C7B;
                  font-weight: 400;
                  font-size: 14px;
                }
                &:-moz-placeholder {
                  color: #7C7C7B;
                  font-weight: 400;
                  font-size: 14px;
                }
              }
              >span.error{
                position: absolute;
                top: 0.5rem;
                right: 1.1rem;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 1.8rem;
                color: #AC2F2F;
                background: #fff;
                z-index: 2;
                height: 3rem;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                        align-items: center;
              }
              label{
                font-size: 14px;
                left: 0.8rem;
                &.yes{
                  font-size: 12px;
                  -webkit-transform: translate3d(0, -3rem, 0);
                          transform: translate3d(0, -3rem, 0);
                  border: 1px solid #b8b8b8;
                  padding: 3px;
                  background:#fff;
                  border-radius: 3px;
                }
              }
              textarea{
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                        align-items: center;
                -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                padding: 2rem;
                width: 100%;
                height: 10rem;
                border: 1px solid #D6D6D6;
                -webkit-border-radius: 0.5rem;
                -moz-border-radius: 0.5rem;
                border-radius: 0.5rem;
                font-family: 'Poppins';
                font-weight: 700;
                font-size: 1.4rem;
                line-height: 2.1rem;
                color:#7C7C7B;
              }
              .button-right{
                text-align: right;
                max-width: 100%;
                margin-top: 2rem;
                margin-bottom: 2rem;
                .buttongrey{
                  font-family: 'Poppins';
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 2.1rem;
                  color: #7C7C7B;
                  padding: 0.6rem 1.6rem;
                  box-sizing: border-box;
                  border: 1px solid #D6D6D6;
                  background: #F2F2F2;
                  -webkit-border-radius: 2rem;
                  -moz-border-radius: 2rem;
                  border-radius: 2rem;
                  text-decoration: none;
                  img{
                    display: inline-block;
                    margin-left: 1rem;
                  }
                  &:hover{
                    text-decoration: none;
                  }
                  &.green{
                    color: #03AC66;
                    border-color: #03AC66;
                  }
                }
              }
              &.error{
                textarea{
                  border-color: #AC2F2F;
                }
                input{
                  border-color: #AC2F2F;
                }
                label{
                  border-color: #AC2F2F;
                  color: #AC2F2F;
                }
              }
              &.succes{
                textarea{
                  border-color: #04ac66;
                }
                input{
                    border-color: #04ac66;
                }
                label{
                    border-color: #04ac66;
                    color: #04ac66;
                }
              }
            }
            &.end{
              padding: 2rem 0;
              display: flex;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
p.error, h4.error, h3.error{
  color: #AC2F2F;
}
.bold{
  font-weight: 700;
}
</style>
